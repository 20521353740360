import { useEffect, useRef, useState } from 'react';
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import {
    webPortfolio,
    appPortfolio,
    designPortfolio,
    commercialPortfolio,
} from "../../data";

export default function Portfolio() {

    const [selected, setSelected] = useState("web");
    const [data, setData] = useState([]);

    const list = [
        {
            id: "web",
            title: "Web",
        },
        {
            id: "applications",
            title: "Applications",
        },
        {
            id: "design",
            title: "Design",
        },
        {
            id: "commercials",
            title: "Commercials",
        },
    ];

    useEffect(() => {
        switch(selected){
            case "web":
                setData(webPortfolio);
                break;
                case "applications":
                setData(appPortfolio);
                break;
                case "design":
                setData(designPortfolio);
                break;
                case "commercials":
                setData(commercialPortfolio);
                break;
                default:
                    setData(webPortfolio);
        }
    }, [selected])

    return (
        <div className="portfolio" id ="portfolio">
           {/* PORTFOLIO-PLACEHOLDER */}
            <h1>Portfolio</h1>
            <ul>
                {list.map(item=>(
                    <PortfolioList 
                    title={item.title} 
                    active={selected === item.id} 
                    setSelected={setSelected}
                    id={item.id}
                    />
                ))}
            </ul>
            <div className="container">
            
                {data.map((d) =>(
                     <a href={d.href}>
                    <div className="item">
                        
                        <img 
                            src={d.img} 
                            alt=""
                        />
                        <h3>{d.title}</h3>
                       
                    </div>
                    </a>
                ))}
            </div>
        </div>
    );
}
