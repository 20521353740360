// rfc + TAB
import "./contact.scss"
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { init, sendForm} from 'emailjs-com';


export default function Contact() {

    
    init("user_V93W7OrYTyScuO6i5I6bu");
    // const [message,setMessage] = useState(false);
    const [statusMessage, setStatusMessage] = useState("Message");
    const [contactNumber, setContactNumber] = useState("000000");

    const generateContactNumber = () => {
        const numStr = "000000" + (Math.random() * 1000000 | 0);
        setContactNumber(numStr.substring(numStr.length - 6));
    }
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        const form = document.querySelector('#contact-form');
        const statusMessage = document.querySelector('.status-message');

        generateContactNumber();
         console.log(data);
        sendForm('default_service', 'template_yakh9ts', '#contact-form', "user_V93W7OrYTyScuO6i5I6bu")
        .then(function(response) {
            setStatusMessage("Thanks for your message, I'll reply As Soon As Possible!");
            statusMessage.className = "status-message success";
            setTimeout(()=> {
                statusMessage.className = 'status-message'
            }, 5000)
            form.reset();
            // console.log('SUCCESS!', response.status, response.text); 
        }, function(error) {
            setStatusMessage("Failed to send message! Please try again later.");
            statusMessage.className = "status-message failure";
            setTimeout(()=> {
                statusMessage.className = 'status-message'
            }, 5000)

            // console.log('FAILED...', error);
        });
    }    
    
    return (
        <div className="contact" id="contact">
            
            <div className="left">
                {/* <img src="assets/Topo3DCheap.png" className="left img" alt=""/> */}
                <img src="assets/currentbestblender.png" className="left img" alt=""/>
            </div>
            <div className="right">
                <h2>Contact.</h2>
                <p className='status-message'>{statusMessage}</p>
                <form class="contact-form" id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                    {/*  onSubmit={handleSubmit} */}
                    {errors.user_email && errors.user_email.type === "required" && (
                          <div role="alert">Email is required<br/></div>
                    )}    
                    <input 
                        type="email" 
                        name="user_email"  
                        aria-invalid={errors.user_email ? "true" : "false"} 
                        {...register('user_email',{
                        required: true})
                        } class="form-control" id="email" placeholder=" Email"/>
                     {errors.message && errors.message.type === "required" && (
                          <div role="alert">Message is required<br/></div>
                    )}        
                    <textarea 
                    class="form-control" 
                    input type="text" 
                    name="message" 
                    aria-invalid={errors.message ? "true" : "false"} 
                    {...register('message',{
                        required: true,
                    })} id="message" placeholder=" Message"></textarea>
                    <input type='hidden' name='contact_number' value={contactNumber} />
                    <button type="submit" value="Send">SEND</button>
                    {/* {message && <span>Thanks for your message, I'll reply As Soon As Possible!</span>} */}
                </form>
            </div>
        </div>
    )
}
