export const webPortfolio = [
    {
        id: "1",
        title: "Caddo Connections",
        img: "assets/caddo_66-2.png",
        href: "https://www.caddoconnections.com/",
    },
    {
        id: "2",
        title: "Krannert Events",
        img: "assets/eventsCheap.png",
        href: "https://mtdemonstration.com/index.php?m=02",
    },
    {
        id: "3",
        title: "Leviathan Software",
        img: "assets/levilogocheap.png",
        href: "http://leviathansoft.com/",
    },
    {
        id: "4",
        title: "Arboretum Wordpress",
        img: "assets/arborCheap.png",
        href: "http://mtarboretum.site/",
    },
    {
        id: "5",
        title: "PinCasts",
        img: "assets/LogoStickerDemo0.png",
        href: "https://www.figma.com/proto/QAiCNz3LqNSMXdP1wYQ9gr/Pin-Concept?node-id=522%3A0&scaling=min-zoom&page-id=0%3A1",
    },
    
];

export const appPortfolio = [
    {
        id: "1",
        title: "ReactTodo",
        img: "assets/todoCheap.png",
        href: "https://mtaddevi.github.io/reactTODO/",
    },
    {
        id: "2",
        title: "IOS CoreML",
        img: "assets/catiosCheap.png",
        href: "https://www.youtube.com/watch?v=NshTBYPq5RQ",
    },
    {
        id: "3",
        title: "Unity3D Game",
        img: "assets/mowiocheap.png",
        href: "https://www.youtube.com/watch?v=2St2Da8aWrc&t=4s",
    },
    {
        id: "4",
        title: "Old Portfolio",
        img: "assets/newPortfolioLogo.png",
        href: "https://mtaddevi.github.io/oldmtportfolio/index.html",
    },
];

export const designPortfolio = [
    {
        id: "1",
        title: "React Portfolio",
        img: "assets/newPortfolioLogo.png",
        href: "https://www.figma.com/proto/ojgkktjPbtDNMdbLMv8an1/PortfolioRefresh?node-id=2%3A2&starting-point-node-id=2%3A2",
    },
    {
        id: "2",
        title: "Caddo Connections",
        img: "assets/caddo_66-2.png",
        href: "https://www.figma.com/proto/ih4v8FV4rAW8TmFqUux78u/CADDO-20-(Copy)?node-id=62%3A0&scaling=min-zoom&page-id=0%3A1",
    },
    {
        id: "3",
        title: "PinCasts",
        img: "assets/LogoStickerDemo0.png",
        href: "https://www.figma.com/proto/QAiCNz3LqNSMXdP1wYQ9gr/Pin-Concept?node-id=522%3A0&scaling=min-zoom&page-id=0%3A1",
    },
];

export const commercialPortfolio = [
    {
        id: "1",
        title: "Quibble Query",
        img: "assets/QuibbleCheap.png",
        href: "https://www.youtube.com/watch?v=-bTed0tCplE",
    },
    {
        id: "2",
        title: "Mobile Money",
        img: "assets/MobileMoneyCheap.png",
        href: "https://www.youtube.com/watch?v=5wW8ha3F2Ok",
    },
];

