import "./intro.scss"
import { useEffect, useRef } from 'react'
import { init } from 'ityped'
// import Typed from 'typed.js';


export default function Intro() {

    const textRef = useRef();
    // const textRef = 'test';

    useEffect(() => {
        // console.log(textRef);
        init(textRef.current, {
            showCursor:true,
            backDelay:1500,
            backSpeed:60,

            strings: ["Designer", "Director", "Graphic Artist"],
        });
    }, []);

    return (
        <div className="intro" id="intro">
            <div className="left">
                    <div className="imgContainer" >
                        <img src="assets/proPhotoRefined.png" alt=""/>
                    </div>
            </div>
            <div className="right">
                <div className="wrapper">
                    <h2>Hello, I'm</h2>
                    <h2 class="pattern-checks-sm bg-blue white">Michael Tadeucci</h2>
                    <h3>Developer + <span ref={textRef}></span></h3>
                </div>
                <a href="#portfolio">
                    {/* DOWN ARROW */}
                    {/* TODO: Replace this Asset, ideally with an animated 'pill' styled button, Gold*/}
                    <div className="downarrow">
                        {/* <img src="assets/cheapDown.jpg" alt=""/> */}
                        ▼
                    </div>
                </a>    
           
                
            </div>
        </div>
    )
}
